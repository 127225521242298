var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vs-row',{style:({ paddingTop: '2%' }),attrs:{"justify":"right"}},[(_vm.products.length > 0)?_c('v-btn',{attrs:{"outlined":"","color":_vm.COLORS.SECONDARY},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}},[_c('span',{staticClass:"material-symbols-outlined",style:({ color: _vm.COLORS.SECONDARY })},[_vm._v(" tune ")]),_c('h5',{style:({ color: _vm.COLORS.SECONDARY, paddingTop: '10px' })},[_vm._v("Filter & Sort")])]):_vm._e()],1),_c('vs-row',[(_vm.drawer)?_c('vs-col',{attrs:{"w":12}},[_c('v-list',[_c('v-list-group',{attrs:{"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"font-size":"18px"}},[_vm._v("Price")])],1)]},proxy:true}],null,false,4057728572),model:{value:(_vm.filterPrice),callback:function ($$v) {_vm.filterPrice=$$v},expression:"filterPrice"}},[_c('div',{staticStyle:{"text-align":"left"}},[_c('v-btn',{attrs:{"outlined":"","rounded":"","filterPrice":_vm.filterPrice == 'all'},on:{"click":function($event){_vm.filterPrice = 'all';
            _vm.forceUpdate();}}},[_vm._v(" All ")]),_c('v-btn',{attrs:{"outlined":"","rounded":"","filterPrice":_vm.filterPrice == 1000},on:{"click":function($event){_vm.filterPrice = 1000;
            _vm.forceUpdate();}}},[_vm._v(" under 1000 ")]),_c('v-btn',{attrs:{"outlined":"","rounded":"","filterPrice":_vm.filterPrice == 1500},on:{"click":function($event){_vm.filterPrice = 1500;
            _vm.forceUpdate();}}},[_vm._v(" under 1500 ")]),_c('v-btn',{attrs:{"outlined":"","rounded":"","filterPrice":_vm.filterPrice == 2000},on:{"click":function($event){_vm.filterPrice = 2000;
            _vm.forceUpdate();}}},[_vm._v(" under 2000 ")]),_c('v-btn',{attrs:{"outlined":"","rounded":"","filterPrice":_vm.filterPrice == 2500},on:{"click":function($event){_vm.filterPrice = 2500;
            _vm.forceUpdate();}}},[_vm._v(" under 2500 ")]),_c('v-btn',{attrs:{"outlined":"","rounded":"","filterPrice":_vm.filterPrice == 3000},on:{"click":function($event){_vm.filterPrice = 3000;
            _vm.forceUpdate();}}},[_vm._v(" under 3000 ")])],1)]),_c('v-list-group',{attrs:{"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"font-size":"18px"}},[_vm._v("Sort")])],1)]},proxy:true}],null,false,2774513227),model:{value:(_vm.filterSort),callback:function ($$v) {_vm.filterSort=$$v},expression:"filterSort"}},[_c('div',{staticStyle:{"text-align":"left"}},[_c('v-btn',{attrs:{"outlined":"","rounded":"","filterSort":_vm.filterSort == 'plh'},on:{"click":function($event){$event.preventDefault();return _vm.filterLH()}}},[_vm._v(" Price: Low-High ")]),_c('v-btn',{attrs:{"outlined":"","rounded":"","filterSort":_vm.filterSort == 'phl'},on:{"click":function($event){$event.preventDefault();return _vm.filterHL()}}},[_vm._v(" Price: High-Low ")]),_c('v-btn',{attrs:{"outlined":"","rounded":"","filterSort":_vm.filterSort == 'la'},on:{"click":function($event){$event.preventDefault();return _vm.filterDL()}}},[_vm._v(" Latest ")]),_c('v-btn',{attrs:{"outlined":"","rounded":"","filterSort":_vm.filterSort == 'ol'},on:{"click":function($event){$event.preventDefault();return _vm.filterDO()}}},[_vm._v(" Oldest ")])],1)])],1)],1):_vm._e(),_c('vs-col',{style:({ padding: '2% 0' }),attrs:{"w":12}},[_c('vs-row',{attrs:{"justify":"left"}},_vm._l((_vm.showProducts),function(item){return _c('vs-col',{key:item.productId,staticStyle:{"font-size":"15px","padding":"0.5%"},attrs:{"w":_vm.$vuetify.breakpoint.smAndDown ? 6 : 4}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
            name: 'EditProduct',
            params: { productId: item.productId },
          }}},[_c('v-card',{style:({ paddingBottom: '10%' }),attrs:{"tile":"","flat":""}},[_c('v-img',{attrs:{"height":_vm.$vuetify.breakpoint.smAndDown ? '250px' : '400px',"src":item.productImages[0]},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"width":"100%","height":"100%","type":"image"}})]},proxy:true}],null,true)}),_c('div',{style:({ padding: '2%' })},[_c('h5',[_vm._v(" "+_vm._s(item.productTitle)+" ")]),_c('div',[_c('strong',[_c('i',{staticClass:"fa-solid fa-bangladeshi-taka-sign"}),_vm._v(" "+_vm._s(item.productPrice))])]),(item.productQuantity == 0)?_c('div',{staticStyle:{"color":"crimson"}},[_c('strong',[_vm._v("Out of Stock")])]):_vm._e(),_c('div',[_vm._v("Colors: "+_vm._s(item.productColors.join(', ')))]),_c('div',[_vm._v("Quantity: "+_vm._s(item.productQuantity))]),_c('div',{staticStyle:{"font-size":"15px"}},[_c('strong',[_vm._v(_vm._s(item.productTags.join(', ')))])]),_c('vs-row',{attrs:{"justify":"right"}},[_c('v-btn',{attrs:{"depressed":"","dark":"","rounded":"","color":_vm.COLORS.RED},on:{"click":function($event){$event.preventDefault();return _vm.deleteProduct(item.productId)}}},[_vm._v(" Delete ")])],1)],1)],1)],1)],1)}),1)],1)],1),_c('Alert',{attrs:{"snackbar":_vm.alert,"text":_vm.alertMessage,"color":_vm.alertColor},on:{"updateSnackbar":_vm.closeSnackbar}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }